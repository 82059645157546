import { Injectable } from "@angular/core";

export enum KeyCodes {
  Enter = "Enter",
  NumpadEnter = "NumpadEnter",
  Space = "Space",
  Esc = "Escape",
  Home = "Home",
  End = "End",
  Del = "Delete",
  A = "KeyA",
  V = "KeyV",
  M = "KeyM",
  H = "KeyH",
  Tab = "Tab",
}

@Injectable()
export class KeyboardService {
  private listeners: { [key in KeyCodes]?: Array<() => void> } = {};

  constructor() {
    this.attach();
  }

  private attach() {
    document.addEventListener("keyup", this.onkeyup, true);
  }

  private onkeyup = (ev: KeyboardEvent) => {
    if (
      !(ev.code in this.listeners) ||
      // if the target is an input field then we are writing something
      (ev.target as HTMLInputElement).tagName === "INPUT" ||
      (ev.target as HTMLTextAreaElement).tagName === "TEXTAREA"
    ) {
      return;
    }

    this.listeners[ev.code][this.listeners[ev.code].length - 1]?.();
  };

  public listen(code: KeyCodes, handler: () => void) {
    this.listeners[code] ??= [];
    this.listeners[code].push(handler);
  }

  public unlisten(code: KeyCodes, handler: () => void) {
    if (code in this.listeners) {
      this.listeners[code] = this.listeners[code].filter((h) => h !== handler);
    }
  }
}
